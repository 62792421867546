// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-ajanvaraus-js": () => import("./../../../src/pages/ajanvaraus.js" /* webpackChunkName: "component---src-pages-ajanvaraus-js" */),
  "component---src-pages-galleria-js": () => import("./../../../src/pages/galleria.js" /* webpackChunkName: "component---src-pages-galleria-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lahjakortti-js": () => import("./../../../src/pages/lahjakortti.js" /* webpackChunkName: "component---src-pages-lahjakortti-js" */),
  "component---src-pages-palvelut-js": () => import("./../../../src/pages/palvelut.js" /* webpackChunkName: "component---src-pages-palvelut-js" */),
  "component---src-pages-yhteystiedot-js": () => import("./../../../src/pages/yhteystiedot.js" /* webpackChunkName: "component---src-pages-yhteystiedot-js" */),
  "component---src-templates-single-service-js": () => import("./../../../src/templates/single-service.js" /* webpackChunkName: "component---src-templates-single-service-js" */)
}

